import MainView from "./components/MainView";
import { Provider } from "react-redux";
import { store } from "./store/store";
import { Routes, Route } from "react-router-dom";
import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import "./css/scroll.css";

const Pages = () => {
  return (
    <Routes>
      <Route path="/" element={<MainView />} />
    </Routes>
  );
};

type AppProps = {
  instance: PublicClientApplication;
};

function App({ instance }: AppProps) {
  const theme = createTheme({
    palette: {
      primary: {
        main: "#454E63",
      },
      secondary: {
        main: "#4261FF",
      },
    },
    typography: {
      allVariants: {
        color: "#FFF",
        textTransform: "none",
        userSelect: "none",
      },
      fontFamily: "DM Sans, sans-serif",
      fontWeightRegular: 700,
      fontWeightLight: 700,
      fontWeightMedium: 700,
      fontWeightBold: 700,
    },
  });

  return (
    <MsalProvider instance={instance}>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <Pages />
        </ThemeProvider>
      </Provider>
    </MsalProvider>
  );
}

export default App;
