import { DBSchema, IDBPDatabase, openDB } from "idb";

interface ImagesDB extends DBSchema {
  blobs: {
    key: string;
    value: {
      id: string;
      blob: Blob;
    };
  };
}

const dbName: string = "blobsDb";
const storeName = "blobs";
const dbVersion: number = 1;

let db: IDBPDatabase<ImagesDB>;

async function tryOpenDbAsync(): Promise<void> {
  if (typeof db !== "undefined") return;
  db = await openDB<ImagesDB>(dbName, dbVersion, {
    upgrade(db) {
      db.createObjectStore(storeName, { keyPath: "id" });
    },
  });
}

export async function saveBlobToIDBAsync(
  blob: Blob,
  id: string
): Promise<void> {
  try {
    await tryOpenDbAsync();
    const transaction = db.transaction(storeName, "readwrite");
    const objectStore = transaction.objectStore(storeName);
    await objectStore.put({ id, blob });
    //console.log("Blob saved to IndexedDB successfully", id);
  } catch (error) {
    console.error("Error saving blob to IndexedDB", error);
  }
}

export async function deleteBlobFromIDBAsync(id: string): Promise<void> {
  try {
    await tryOpenDbAsync();
    const transaction = db.transaction(storeName, "readwrite");
    const objectStore = transaction.objectStore(storeName);
    await objectStore.delete(id);
    console.log("Blob deleted from IndexedDB successfully", id);
  } catch (error) {
    console.error("Error deleting blob from IndexedDB", error);
  }
}

export async function existBlobInIDBAsync(id: string): Promise<boolean> {
  try {
    await tryOpenDbAsync();
    const transaction = db.transaction(storeName, "readonly");
    const objectStore = transaction.objectStore(storeName);
    const record = await objectStore.getKey(id);

    if (record) {
      return true;
    } else {
      console.log("Absent in local storage", id);
    }
  } catch (error) {
    console.error("Error retrieving key from IndexedDB", error);
  }
  return false;
}

export async function getBlobFromIDBAsync(
  id: string
): Promise<Blob | undefined> {
  try {
    await tryOpenDbAsync();
    const transaction = db.transaction(storeName, "readonly");
    const objectStore = transaction.objectStore(storeName);
    const record = await objectStore.get(id);

    if (record) {
      return record.blob;
    } else {
      console.log("No blob found with the provided ID", id);
      return undefined;
    }
  } catch (error) {
    console.error("Error retrieving blob from IndexedDB", error);
  }
}
