import { Typography } from "@mui/material";

type GradientTypographyProps = {
  fontSize: string;
  text: string;
};

const GradientTypography = ({ fontSize, text }: GradientTypographyProps) => {
  return (
    <Typography
      sx={{
        fontSize: fontSize,
        color: "transparent",
        background:
          "linear-gradient(45deg, #fb0094, #00bbff, #0000ff, #fb0094, #00bbff, #0000ff, #fb0094)",
        backgroundSize: "200% auto",
        animation: "steam 20s linear infinite",
        WebkitBackgroundClip: "text",
        backgroundClip: "text",
      }}
    >
      {text}
    </Typography>
  );
};

export default GradientTypography;
