import { Typography, Button, Divider, CircularProgress } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { useEffect, useState } from "react";
import {
  getUserId,
  getUserLeftCredits,
  getUserMail,
  getUserSubscriptionCancelled,
  getUserSubscriptionEndDate,
  getUserSubscriptionLevel,
} from "../store/userSlice";
import {
  cancelUserSubscriptionAsync,
  getUserBlobAsync,
  getUserSubscriptionInfo,
  postUserBlobAsync,
} from "../managers/storage/AzureStorageManager";
import {
  capitalizeFirstLetter,
  fixSubscriptionName,
  formatTimestamp,
  replaceDashesWithSpaces,
  replaceUnderscoresWithSpaces,
  resizeImage,
  roundIfFloat,
} from "../helpers/Helper";
import { saveBlobToIDBAsync } from "../managers/storage/DbManager";
import { handleAddAndCheckout } from "../managers/FastSpring";
import ConfirmationDialog from "./dialogs/ConfirmationDialog";
import EditRoundedIcon from "@mui/icons-material/EditRounded";

enum subscriptionLevels {
  none,
  standard,
  advanced,
  professional,
}

enum subscriptionTypes {
  monthly,
  yearly,
}

const tools = [
  "3D Landscape Relight",
  "3D Portrait Relight",
  "Sky Remixer",
  "LUT Generator",
  "Upscale",
  "Background Removal",
];

const SettingsView = () => {
  const dispatch = useAppDispatch();
  const subscriptionLevel = useAppSelector(getUserSubscriptionLevel);
  const subscriptionCancelled = useAppSelector(getUserSubscriptionCancelled);
  const subscriptionEndDate = useAppSelector(getUserSubscriptionEndDate);
  const mail = useAppSelector(getUserMail);
  const userId = useAppSelector(getUserId);
  const userLeftCredits = useAppSelector(getUserLeftCredits);
  const [url, setUrl] = useState("assets/images/user.webp");
  const [hoveredSubscriptionLevel, setHoveredSubscriptionLevel] = useState(
    subscriptionLevels.none
  );
  const [subscriptionType, setSubscriptionType] = useState(
    subscriptionTypes.monthly
  );
  const [subscriptionLoading, setSubscriptionLoading] = useState(false);
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [confirmationDialogMessage, setConfirmationDialogMessage] =
    useState("");
  const [confirmationDialogItem, setConfirmationDialogItem] = useState("");
  const [onConfirm, setOnConfirm] = useState<Function>(() => () => {});
  const [isWidthSufficient, setIsWidthSufficient] = useState(
    window.innerWidth > 520
  );

  useEffect(() => {
    const handleResize = () => {
      setIsWidthSufficient(window.innerWidth > 520);
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (subscriptionLevel.endsWith("yearly")) {
      setSubscriptionType(subscriptionTypes.yearly);
    }
  }, [subscriptionLevel]);

  useEffect(() => {
    const asyncWrapper = async () => {
      if (!userId) return;
      let blob = await getUserBlobAsync("user/icon/icon.png", userId);
      if (blob) {
        setUrl(URL.createObjectURL(blob));
      }
    };
    asyncWrapper();
  }, [userId]);

  const handleCancelSubscription = () => {
    setConfirmationDialogMessage(
      "You will be able to access all features until the end of your current billing period. However, once this period concludes, you will lose access to these features."
    );
    setOnConfirm(() => cancelSubscriptionAsync);
    setConfirmationDialogOpen(true);
  };

  const cancelSubscriptionAsync = async () => {
    setSubscriptionLoading(true);

    await cancelUserSubscriptionAsync(userId);
    await getUserSubscriptionInfo(userId, dispatch);

    setSubscriptionLoading(false);
  };

  const handleGetSubscription = (subscription: string) => {
    if (subscriptionLevel === "demo" || subscriptionCancelled) {
      getSubscription(subscription);
      return;
    }

    setConfirmationDialogItem(subscription);
    setConfirmationDialogMessage(
      "Please note that by opting for a new subscription plan now, you will transition from your current plan to the new one. According to our policy, we are unable to offer a refund for the remaining time on your current plan."
    );
    setOnConfirm(() => getSubscription);
    setConfirmationDialogOpen(true);
  };

  const getSubscription = (subscription: string) => {
    handleAddAndCheckout(
      userId,
      mail!,
      subscriptionType === subscriptionTypes.monthly
        ? subscription
        : subscription.toLowerCase() + "-yearly",
      dispatch
    );
  };

  const handleChangeIcon = () => {
    const input = document.createElement("input");
    input.type = "file";

    input.onchange = async () => {
      await uploadIcon(input.files);
    };

    input.click();
  };

  const uploadIcon = async (files: any) => {
    const file = files?.[0];
    if (!file) return;
    const resizedBlob = await new Promise((resolve, reject) => {
      resizeImage(file as File, 512, 512, (result: File) => {
        result ? resolve(result) : reject(new Error("Error resizing image"));
      });
    });

    await postUserBlobAsync(resizedBlob as Blob, "user/icon/icon.png", userId);
    await saveBlobToIDBAsync(resizedBlob as Blob, "user/icon/icon.png");

    setUrl(URL.createObjectURL(resizedBlob as Blob));
  };

  const handleMouseEnter = (level: subscriptionLevels) => {
    setHoveredSubscriptionLevel(level);
  };

  const handleMouseLeave = () => {
    setHoveredSubscriptionLevel(subscriptionLevels.none);
  };

  const handleCloseConfirmationDialog = () => {
    setConfirmationDialogOpen(false);
    setConfirmationDialogMessage("");
    setConfirmationDialogItem("");
    setOnConfirm(() => () => {});
  };

  return (
    <div
      style={{
        display: "flex",
        width: "calc(100% - 32px)",
        height: "calc(100% - 32px)",
        margin: "16px",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          position: "relative",
          justifyContent: "space-between",
          height: "100%",
          width: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            borderRadius: "12px",
            backgroundColor: "#050913",
            marginTop: "92px",
            height: "calc(100% - 92px)",
            border: "1px solid #A9A7B0",
            overflow: "hidden",
            position: "relative",
          }}
        >
          <div
            style={{
              overflow: "hidden",
              height: "100%",
              width: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: isWidthSufficient ? "row" : "column",
                justifyContent: isWidthSufficient ? "space-between" : "center",
                alignItems: isWidthSufficient ? "normal" : "center",
                width: isWidthSufficient ? "auto" : "100%",
              }}
            >
              <div
                style={{
                  justifyContent: isWidthSufficient ? "normal" : "center",
                }}
              >
                <Typography
                  sx={{
                    marginTop: "24px",
                    marginLeft: isWidthSufficient ? "20px" : 0,
                    fontSize: "20px",
                    fontWeight: 900,
                  }}
                >
                  {fixSubscriptionName(
                    capitalizeFirstLetter(
                      replaceDashesWithSpaces(
                        replaceUnderscoresWithSpaces(subscriptionLevel)
                      )
                    ).split(" ")[0]
                  )}
                </Typography>
                <Typography
                  sx={{
                    marginLeft: isWidthSufficient ? "20px" : 0,
                    fontSize: "14px",
                    fontWeight: 600,
                    opacity: 0.7,
                    color: "#FFF",
                  }}
                >
                  {subscriptionEndDate
                    ? subscriptionCancelled
                      ? `Subscription ends: ${formatTimestamp(
                          subscriptionEndDate
                        )}`
                      : `Next charge: ${formatTimestamp(subscriptionEndDate)}`
                    : "Current plan"}
                </Typography>
              </div>
              <Typography
                sx={{
                  marginTop: "12px",
                  alignSelf: "center",
                  marginRight: isWidthSufficient ? "20px" : 0,
                  fontWeight: 800,
                  fontSize: "18px",
                }}
              >
                Credits Left: {roundIfFloat(userLeftCredits)}
              </Typography>
            </div>
            <Divider
              sx={{
                backgroundColor: "#FFF",
                color: "#FFF",
              }}
            />

            <div
              style={{
                display: "flex",
                width: "100%",
                height: isWidthSufficient
                  ? "calc(100% - 108px)"
                  : "calc(100% - 188px)",
                justifyContent: "space-evenly",
                alignItems: "center",
                overflowY: "auto",
                flexWrap: "wrap",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  backgroundColor: "",
                  display: "flex",
                  justifyContent: "center",
                  height: "40px",
                  minHeight: "40px",
                  maxHeight: "40px",
                  alignSelf: "center",
                  gap: "8px",
                }}
              >
                <Button
                  onClick={() => {
                    setSubscriptionType(subscriptionTypes.monthly);
                  }}
                  sx={{
                    width: "128px",
                    backgroundColor:
                      subscriptionType === subscriptionTypes.monthly
                        ? "#7569ff"
                        : "",
                    borderRadius: "12px",
                    ":hover": {
                      backgroundColor:
                        subscriptionType === subscriptionTypes.monthly
                          ? "#7569ff"
                          : "#7569ff",
                    },
                    "& .MuiTouchRipple-root": {
                      display: "none",
                    },
                  }}
                >
                  <Typography
                    style={{
                      fontSize: "15px",
                      fontWeight: 600,
                    }}
                  >
                    Monthly
                  </Typography>
                </Button>
                <Button
                  onClick={() => {
                    setSubscriptionType(subscriptionTypes.yearly);
                  }}
                  sx={{
                    width: "128px",
                    backgroundColor:
                      subscriptionType === subscriptionTypes.yearly
                        ? "#7569ff"
                        : "",
                    borderRadius: "12px",
                    ":hover": {
                      backgroundColor:
                        subscriptionType === subscriptionTypes.yearly
                          ? "#7569ff"
                          : "#7569ff",
                    },
                    "& .MuiTouchRipple-root": {
                      display: "none",
                    },
                  }}
                >
                  <Typography
                    style={{
                      fontSize: "15px",
                      fontWeight: 600,
                    }}
                  >
                    Yearly
                  </Typography>
                </Button>
              </div>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  height: isWidthSufficient
                    ? "calc(100% - 108px)"
                    : "calc(100% - 188px)",
                  justifyContent: "space-evenly",
                  alignItems: "center",
                  flexWrap: "wrap",
                }}
              >
                <div
                  onMouseEnter={() => {
                    handleMouseEnter(subscriptionLevels.standard);
                  }}
                  onMouseLeave={() => {
                    handleMouseLeave();
                  }}
                  style={{
                    flex: 1,
                    minWidth: "260px",
                    margin: "20px 40px",
                    height: "580px",
                    backgroundColor: "#0d111a",
                    borderRadius: "16px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    border:
                      !subscriptionCancelled &&
                      ((subscriptionLevel === "standart-yearly" &&
                        subscriptionType === subscriptionTypes.yearly) ||
                        (subscriptionLevel === "standart" &&
                          subscriptionType === subscriptionTypes.monthly))
                        ? "1px solid #7569ff"
                        : "",
                  }}
                >
                  <Typography
                    style={{
                      fontSize: "24px",
                      fontWeight: 700,
                      fontFamily: "Plus Jakarta Sans, sans-serif",
                    }}
                  >
                    Standard
                  </Typography>
                  <Typography
                    style={{
                      color: "#A9A7B0",
                      fontWeight: 400,
                      fontSize: "18px",
                    }}
                  >
                    100 credits / month
                  </Typography>

                  <Typography
                    style={{
                      fontSize: "50px",
                      marginTop: "20px",
                      fontFamily: "Plus Jakarta Sans, sans-serif",
                      color:
                        hoveredSubscriptionLevel === subscriptionLevels.standard
                          ? "#7569ff"
                          : "#FFF",
                      transition: "color 0.3s ease-in-out",
                    }}
                  >
                    $
                    {subscriptionType === subscriptionTypes.monthly
                      ? "4.99"
                      : "49.99"}
                  </Typography>
                  <Typography
                    style={{
                      color: "#74727B",
                      fontSize: "18px",
                      fontWeight: 400,
                      marginBottom: "32px",
                    }}
                  >
                    Per{" "}
                    {subscriptionType === subscriptionTypes.monthly
                      ? "Month"
                      : "Year"}
                  </Typography>
                  {tools.map((tool) => (
                    <Typography
                      key={tool}
                      style={{
                        color: "#A9A7B0",
                        fontSize: "18px",
                        fontWeight: 400,
                      }}
                    >
                      {tool}
                    </Typography>
                  ))}
                  {!subscriptionCancelled &&
                  ((subscriptionLevel === "standart-yearly" &&
                    subscriptionType === subscriptionTypes.yearly) ||
                    (subscriptionLevel === "standart" &&
                      subscriptionType === subscriptionTypes.monthly)) ? (
                    <Button
                      onClick={handleCancelSubscription}
                      sx={{
                        width: "140px",
                        height: "54px",
                        borderRadius: "8px",
                        marginTop: "84px",
                        backgroundColor: "#eb4034",
                        ":hover": {
                          backgroundColor: "#e6382c",
                        },
                        transition: "0.3s ease-in-out",
                      }}
                    >
                      {subscriptionLoading ? (
                        <CircularProgress
                          size={"16px"}
                          sx={{
                            marginLeft: "8px",
                            color: "white",
                          }}
                        />
                      ) : (
                        <Typography
                          style={{
                            fontSize: "15px",
                            fontWeight: 600,
                            fontFamily: "Plus Jakarta Sans, sans-serif",
                          }}
                        >
                          Cancel
                        </Typography>
                      )}
                    </Button>
                  ) : (
                    <Button
                      onClick={() => {
                        handleGetSubscription("standart");
                      }}
                      sx={{
                        width: "140px",
                        height: "54px",
                        border:
                          hoveredSubscriptionLevel ===
                          subscriptionLevels.standard
                            ? "2px solid #7569ff"
                            : "2px solid white",
                        borderRadius: "8px",
                        marginTop: "64px",
                        backgroundColor:
                          hoveredSubscriptionLevel ===
                          subscriptionLevels.standard
                            ? "#7569ff"
                            : "",
                        ":hover": {
                          backgroundColor: "#7569ff",
                          borderColor: "#7569ff",
                        },
                        transition: "0.3s ease-in-out",
                      }}
                    >
                      <Typography
                        style={{
                          fontSize: "15px",
                          fontWeight: 600,
                          fontFamily: "Plus Jakarta Sans, sans-serif",
                        }}
                      >
                        Get Now
                      </Typography>
                    </Button>
                  )}
                </div>
                <div
                  onMouseEnter={() => {
                    handleMouseEnter(subscriptionLevels.advanced);
                  }}
                  onMouseLeave={() => {
                    handleMouseLeave();
                  }}
                  style={{
                    position: "relative",
                    flex: 1,
                    minWidth: "260px",
                    height: "580px",
                    backgroundColor: "#0d111a",
                    borderRadius: "16px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    overflow: "hidden",
                    margin: "20px 40px",
                    border:
                      !subscriptionCancelled &&
                      ((subscriptionLevel === "advanced-yearly" &&
                        subscriptionType === subscriptionTypes.yearly) ||
                        (subscriptionLevel === "advanced" &&
                          subscriptionType === subscriptionTypes.monthly))
                        ? "1px solid #7569ff"
                        : "",
                  }}
                >
                  <div
                    style={{
                      position: "absolute",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: "#7569ff",
                      left: -40,
                      top: 32,
                      transform: "rotate(315deg)",
                      transformOrigin: "center",
                    }}
                  >
                    <Typography
                      style={{ margin: "4px 64px", fontSize: "11px" }}
                    >
                      POPULAR
                    </Typography>
                  </div>
                  <Typography
                    style={{
                      fontSize: "24px",
                      fontWeight: 700,
                      fontFamily: "Plus Jakarta Sans, sans-serif",
                    }}
                  >
                    Advanced
                  </Typography>
                  <Typography
                    style={{
                      color: "#A9A7B0",
                      fontWeight: 400,
                      fontSize: "18px",
                    }}
                  >
                    250 credits / month
                  </Typography>
                  <Typography
                    style={{
                      fontSize: "50px",
                      marginTop: "20px",
                      fontFamily: "Plus Jakarta Sans, sans-serif",
                      color:
                        hoveredSubscriptionLevel === subscriptionLevels.advanced
                          ? "#7569ff"
                          : "#FFF",
                      transition: "color 0.3s ease-in-out",
                    }}
                  >
                    $
                    {subscriptionType === subscriptionTypes.monthly
                      ? "7.99"
                      : "79.99"}
                  </Typography>
                  <Typography
                    style={{
                      color: "#74727B",
                      fontSize: "18px",
                      fontWeight: 400,
                      marginBottom: "32px",
                    }}
                  >
                    Per{" "}
                    {subscriptionType === subscriptionTypes.monthly
                      ? "Month"
                      : "Year"}
                  </Typography>
                  {tools.map((tool) => (
                    <Typography
                      key={tool}
                      style={{
                        color: "#A9A7B0",
                        fontSize: "18px",
                        fontWeight: 400,
                      }}
                    >
                      {tool}
                    </Typography>
                  ))}
                  {!subscriptionCancelled &&
                  ((subscriptionLevel === "advanced-yearly" &&
                    subscriptionType === subscriptionTypes.yearly) ||
                    (subscriptionLevel === "advanced" &&
                      subscriptionType === subscriptionTypes.monthly)) ? (
                    <Button
                      onClick={handleCancelSubscription}
                      sx={{
                        width: "140px",
                        height: "54px",
                        borderRadius: "8px",
                        marginTop: "56px",
                        backgroundColor: "#eb4034",
                        ":hover": {
                          backgroundColor: "#e6382c",
                        },
                        transition: "0.3s ease-in-out",
                      }}
                    >
                      {subscriptionLoading ? (
                        <CircularProgress
                          size={"16px"}
                          sx={{
                            marginLeft: "8px",
                            color: "white",
                          }}
                        />
                      ) : (
                        <Typography
                          style={{
                            fontSize: "15px",
                            fontWeight: 600,
                            fontFamily: "Plus Jakarta Sans, sans-serif",
                          }}
                        >
                          Cancel
                        </Typography>
                      )}
                    </Button>
                  ) : (
                    <Button
                      onClick={() => {
                        handleGetSubscription("advanced");
                      }}
                      sx={{
                        backgroundColor: "#7569ff",
                        width: "140px",
                        height: "54px",
                        border: "2px solid #7569ff",
                        borderRadius: "8px",
                        marginTop: "64px",
                        ":hover": {
                          backgroundColor: "#7569ff",
                          borderColor: "#7569ff",
                        },
                      }}
                    >
                      <Typography
                        style={{
                          fontSize: "15px",
                          fontWeight: 600,
                          fontFamily: "Plus Jakarta Sans, sans-serif",
                        }}
                      >
                        Get Now
                      </Typography>
                    </Button>
                  )}
                </div>
                <div
                  onMouseEnter={() => {
                    handleMouseEnter(subscriptionLevels.professional);
                  }}
                  onMouseLeave={() => {
                    handleMouseLeave();
                  }}
                  style={{
                    flex: 1,
                    minWidth: "260px",
                    margin: "20px 40px",
                    height: "580px",
                    backgroundColor: "#0d111a",
                    borderRadius: "16px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    border:
                      !subscriptionCancelled &&
                      ((subscriptionLevel === "professional-yearly" &&
                        subscriptionType === subscriptionTypes.yearly) ||
                        (subscriptionLevel === "Professional" &&
                          subscriptionType === subscriptionTypes.monthly))
                        ? "1px solid #7569ff"
                        : "",
                  }}
                >
                  <Typography
                    style={{
                      fontSize: "24px",
                      fontWeight: 700,
                      fontFamily: "Plus Jakarta Sans, sans-serif",
                    }}
                  >
                    Professional+
                  </Typography>
                  <Typography
                    style={{
                      color: "#A9A7B0",
                      fontWeight: 400,
                      fontSize: "18px",
                    }}
                  >
                    600 credits / month
                  </Typography>
                  <Typography
                    style={{
                      fontSize: "50px",
                      marginTop: "20px",
                      fontFamily: "Plus Jakarta Sans, sans-serif",
                      color:
                        hoveredSubscriptionLevel ===
                        subscriptionLevels.professional
                          ? "#7569ff"
                          : "#FFF",
                      transition: "color 0.3s ease-in-out",
                    }}
                  >
                    $
                    {subscriptionType === subscriptionTypes.monthly
                      ? "14.99"
                      : "149.99"}
                  </Typography>
                  <Typography
                    style={{
                      color: "#74727B",
                      fontSize: "18px",
                      fontWeight: 400,
                      marginBottom: "32px",
                    }}
                  >
                    Per{" "}
                    {subscriptionType === subscriptionTypes.monthly
                      ? "Month"
                      : "Year"}
                  </Typography>
                  {tools.map((tool) => (
                    <Typography
                      key={tool}
                      style={{
                        color: "#A9A7B0",
                        fontSize: "18px",
                        fontWeight: 400,
                      }}
                    >
                      {tool}
                    </Typography>
                  ))}
                  {!subscriptionCancelled &&
                  ((subscriptionLevel === "professional-yearly" &&
                    subscriptionType === subscriptionTypes.yearly) ||
                    (subscriptionLevel === "Professional" &&
                      subscriptionType === subscriptionTypes.monthly)) ? (
                    <Button
                      onClick={handleCancelSubscription}
                      sx={{
                        width: "140px",
                        height: "54px",
                        borderRadius: "8px",
                        marginTop: "28px",
                        backgroundColor: "#eb4034",
                        ":hover": {
                          backgroundColor: "#e6382c",
                        },
                        transition: "0.3s ease-in-out",
                      }}
                    >
                      {subscriptionLoading ? (
                        <CircularProgress
                          size={"16px"}
                          sx={{
                            marginLeft: "8px",
                            color: "white",
                          }}
                        />
                      ) : (
                        <Typography
                          style={{
                            fontSize: "15px",
                            fontWeight: 600,
                            fontFamily: "Plus Jakarta Sans, sans-serif",
                          }}
                        >
                          Cancel
                        </Typography>
                      )}
                    </Button>
                  ) : (
                    <Button
                      onClick={() => {
                        handleGetSubscription("Professional");
                      }}
                      sx={{
                        width: "140px",
                        height: "54px",
                        border:
                          hoveredSubscriptionLevel ===
                          subscriptionLevels.professional
                            ? "2px solid #7569ff"
                            : "2px solid white",
                        borderRadius: "8px",
                        marginTop: "64px",
                        backgroundColor:
                          hoveredSubscriptionLevel ===
                          subscriptionLevels.professional
                            ? "#7569ff"
                            : "",
                        ":hover": {
                          backgroundColor: "#7569ff",
                          borderColor: "#7569ff",
                        },
                        transition: "0.3s ease-in-out",
                      }}
                    >
                      <Typography
                        style={{
                          fontSize: "15px",
                          fontWeight: 600,
                          fontFamily: "Plus Jakarta Sans, sans-serif",
                        }}
                      >
                        Get Now
                      </Typography>
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            position: "absolute",
            top: 0,
            right: "28px",
            flexDirection: "row-reverse",
          }}
        >
          <div style={{ position: "relative" }}>
            <img
              src={url}
              width="100px"
              height="100px"
              style={{
                objectFit: "cover",
                borderRadius: "100px",
                border: "4px solid #FFF",
                backgroundColor: "#FFF",
              }}
            />
            <Button
              onClick={handleChangeIcon}
              sx={{
                position: "absolute",
                top: "2px",
                right: "2px",
                maxWidth: "28px",
                minWidth: "28px",
                maxHeight: "28px",
                borderRadius: "28px",
                backgroundColor: "#7569ff",
              }}
            >
              <EditRoundedIcon fontSize="small" style={{ color: "white" }} />
            </Button>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginRight: "4px",
            }}
          >
            <Typography
              sx={{
                fontSize: "16px",
                lineHeight: "24px",
                fontWeight: 700,
                opacity: "1",
                marginRight: "8px",
                borderBottom: "1px dashed #FFF",
                userSelect: "auto",
                marginBottom: "12px",
              }}
            >
              {mail}
            </Typography>
          </div>
        </div>
      </div>
      {confirmationDialogOpen && (
        <ConfirmationDialog
          item={confirmationDialogItem}
          header="Confirm"
          description={confirmationDialogMessage}
          confirmButtonText="Proceed"
          onCancel={handleCloseConfirmationDialog}
          onConfirm={onConfirm}
        />
      )}
    </div>
  );
};

export default SettingsView;
