export function timeoutAsync(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export const formatTimestamp = (timestamp: number) => {
  const date = new Date(timestamp);

  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const year = String(date.getFullYear()).slice(-2);

  return `${month}/${day}/${year}`;
};

export const resizeImage = (
  blob: Blob,
  maxWidth: number,
  maxHeight: number,
  callback: any
) => {
  // Step 1: Read the Blob as Data URL
  var reader = new FileReader();
  reader.readAsDataURL(blob);
  reader.onloadend = function () {
    var base64data = reader.result;

    // Step 2: Create an Image Object
    var img = new Image();
    img.src = base64data as string;
    img.onload = function () {
      // Step 3: Calculate Aspect Ratio
      const originalWidth = img.width;
      const originalHeight = img.height;
      const aspectRatio = originalWidth / originalHeight;

      let newWidth = originalWidth;
      let newHeight = originalHeight;

      // If the image is wider than the desired width, scale it down
      if (originalWidth > maxWidth) {
        newWidth = maxWidth;
        newHeight = newWidth / aspectRatio;
      }

      // If the new height is still taller than the desired height, scale it down further
      if (newHeight > maxHeight) {
        newHeight = maxHeight;
        newWidth = newHeight * aspectRatio;
      }

      // Step 4: Draw the Image on Canvas
      var canvas = document.createElement("canvas");
      var ctx = canvas.getContext("2d");
      canvas.width = newWidth; // Set new width
      canvas.height = newHeight; // Set new height

      ctx!.drawImage(img, 0, 0, newWidth, newHeight);

      // Step 5: Extract the New Image
      canvas.toBlob(
        function (newBlob) {
          callback(newBlob);
        },
        "image/jpg",
        1
      ); // Set the format and quality here
    };
  };
};

export function capitalizeFirstLetter(str: string) {
  return str
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
}

export function replaceUnderscoresWithSpaces(str: string) {
  return str.replace("_", " ");
}

export function replaceDashesWithSpaces(str: string) {
  return str.replace("-", " ");
}

export function fixSubscriptionName(str: string) {
  if (str === "Professional") {
    return "Professional+";
  }
  if (str === "Standart") {
    return "Standard";
  }
  return str;
}

export function roundIfFloat(num: number) {
  if (num % 1 !== 0) {
    return Math.round(num * 10) / 10;
  }
  return num;
}

export function decodeEmail(encodedEmail: string) {
  return encodedEmail.replace(/-at-/g, "@").replace(/-dot-/g, ".");
}

export function encodeEmail(email: string) {
  return email.replace(/@/g, "-at-").replace(/\./g, "-dot-");
}
