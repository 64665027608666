import { Box, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { timeoutAsync } from "../../helpers/Helper";
import GradientTypography from "./GradientTypography";

type OpeningProps = {
  loaded: boolean;
};

const Opening = ({ loaded }: OpeningProps) => {
  const [opacity, setOpacity] = useState(0);
  const [fadeOut, setFadeOut] = useState(1);
  const [finished, setFinished] = useState(false);
  const [blur, setBlur] = useState(0);
  const [scale, setScale] = useState(0.1);
  const [innerScale, setInnerScale] = useState(1);
  const loadedRef = useRef(loaded);
  const counter = useRef(0);

  useEffect(() => {
    loadedRef.current = loaded;
  }, [loaded]);

  useEffect(() => {
    const asyncWrapper = async () => {
      await timeoutAsync(100);
      setOpacity(1);
      setScale(1);
      setInnerScale(1);
      await timeoutAsync(1000);
      while (!loadedRef.current) {
        if (counter.current % 2 === 0) {
          setInnerScale(0.9);
        } else {
          setInnerScale(1.1);
        }
        await timeoutAsync(500);
        counter.current++;
      }
      setFadeOut(0);
      setBlur(16);
      setScale(16);
      setInnerScale(1);
      await timeoutAsync(1000);
      setFinished(true);
    };
    asyncWrapper();
  }, []);

  return (
    <>
      {!finished && (
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "#050913",
            zIndex: 1000,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            transition: "opacity 1s",
            opacity: fadeOut,
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              transition: "opacity 1s",
              opacity: opacity,
              width: "25%",
              height: "25%",
            }}
          >
            <Box
              sx={{
                transition: "transform 1s",
                transform: `scale(${scale}, ${scale})`,
                width: "100%",
                height: "100%",
              }}
            >
              <Box
                sx={{
                  transition: "transform 1s",
                  transform: `scale(${innerScale}, ${innerScale})`,
                  width: "100%",
                  height: "100%",
                }}
              >
                <img
                  src="assets/images/icon-fotogeniq.png"
                  width="100%"
                  height="100%"
                  style={{
                    filter: `blur(${blur}px)`,
                    transition: "filter 1s",
                    objectFit: "contain",
                  }}
                />
              </Box>
            </Box>
            <div
              style={{
                position: "absolute",
                bottom: "32px",
                transition: "opacity 0.3s",
                opacity: fadeOut,
              }}
            >
              <GradientTypography fontSize="20px" text="Powered by AI" />
            </div>
          </Box>
        </Box>
      )}
    </>
  );
};
export default Opening;
