import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "./store";
import { UserSettings } from "./types";

const initialState: UserSettings = {
  subscriptionLevel: "demo",
  subscriptionCancelled: false,
  subscriptionEndDate: 0,
  userId: "",
  credits: 0,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserId: (state, action) => {
      state.userId = action.payload;
    },
    setUserLeftCredits: (state, action) => {
      state.credits = action.payload;
    },
    setUserMail: (state, action) => {
      state.mail = action.payload;
    },
    setUserSubscription: (state, action) => {
      state.subscriptionLevel = action.payload;
    },
    setUserSubscriptionCancelled: (state, action) => {
      state.subscriptionCancelled = action.payload;
    },
    setUserSubscriptionEndDate: (state, action) => {
      state.subscriptionEndDate = action.payload;
    },
  },
});

export const getUserSubscriptionLevel = (state: RootState) =>
  state.user.subscriptionLevel;
export const getUserSubscriptionCancelled = (state: RootState) =>
  state.user.subscriptionCancelled;
export const getUserSubscriptionEndDate = (state: RootState) =>
  state.user.subscriptionEndDate;
export const getUserLeftCredits = (state: RootState) => state.user.credits;
export const getUserMail = (state: RootState) => state.user.mail;
export const getUserId = (state: RootState) => state.user.userId;
export const {
  setUserId,
  setUserMail,
  setUserSubscription,
  setUserSubscriptionCancelled,
  setUserSubscriptionEndDate,
  setUserLeftCredits,
} = userSlice.actions;

export default userSlice.reducer;
