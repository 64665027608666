import { apiUrl } from "../settings/GlobalSettings";
import { PublicClientApplication } from "@azure/msal-browser";
import { loginRequest, msalConfig } from "../authConfig";

const msalInstance = new PublicClientApplication(msalConfig);

export async function getUserAccount() {
  try {
    const result = await msalInstance.acquireTokenSilent({
      scopes: [],
    });

    return result.account;
  } catch (err) {
    console.log(err);
    msalInstance
      .loginRedirect(loginRequest)
      .catch((error) => console.log(error));
    return "";
  }
}

export async function getUserSettingsAsync(userId: string) {
  const response = await fetch(`${apiUrl}/api/GetUserSettings`, {
    method: "GET",
    headers: {
      "agent-uuid": userId,
    },
  });
  const result = await response.json();
  return result;
}

export async function getUserLeftCreditsAsync(userId: string) {
  const response = await fetch(`${apiUrl}/api/GetUserCredits`, {
    method: "GET",
    headers: {
      "agent-uuid": userId,
    },
  });
  const result = await response.json();
  return result;
}
