import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "./store";
import { DialogModel } from "./types";

const initialState: DialogModel = {
  customAlert: "",
};

export const dialogSlice = createSlice({
  name: "dialog",
  initialState,
  reducers: {
    showCustomAlert: (state, action) => {
      state.customAlert = action.payload;
    },
    hideCustomAlert: (state) => {
      state.customAlert = "";
    },
  },
});

export const getCustomAlertText = (state: RootState) =>
  state.dialog.customAlert;

export const { showCustomAlert, hideCustomAlert } = dialogSlice.actions;

export default dialogSlice.reducer;
