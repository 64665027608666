import { setUserLeftCredits } from "../store/userSlice";
import { getUserLeftCreditsAsync } from "./ClientManager";
import { getUserSubscriptionInfo } from "./storage/AzureStorageManager";

export function handleAddAndCheckout(
  userId: string,
  mail: string,
  subscriptionLevel: string,
  dispatch: Function
) {
  window.fastspring.builder.recognize({
    email: mail,
    firstName: "",
    lastName: "",
  });

  window.fastspring.builder.add(subscriptionLevel);
  window.fastspring.builder.checkout();

  window.dataPopupWebhookReceived = async (event: any) => {
    console.log("Webhook received:", event);
    await getUserSubscriptionInfo(userId, dispatch);
    dispatch(setUserLeftCredits(await getUserLeftCreditsAsync(userId)));
  };

  window.dataPopupClosed = async (event: any) => {
    console.log("Popup closed:", event);
    await getUserSubscriptionInfo(userId, dispatch);
    dispatch(setUserLeftCredits(await getUserLeftCreditsAsync(userId)));
  };
}
