import { apiUrl } from "../../settings/GlobalSettings";
import axios, { AxiosRequestConfig } from "axios";
import {
  setUserSubscription,
  setUserSubscriptionCancelled,
  setUserSubscriptionEndDate,
} from "../../store/userSlice";

export async function getUserBlobAsync(blobPath: string, userId: string) {
  try {
    const config: AxiosRequestConfig = {
      responseType: "blob",
    };

    const sasUrl = await getUserBlobSasUrl(blobPath, userId);
    const response = await axios.get(sasUrl, config);

    if (response.status !== 200) {
      return undefined;
    }

    return response.data;
  } catch (error) {}
}

export async function postUserBlobAsync(
  blob: Blob,
  blobPath: string,
  userId: string
) {
  const sasUrl = await getUserBlobSasUrl(blobPath, userId);

  const config = {
    headers: {
      "x-ms-blob-type": "BlockBlob",
      "Content-Type": blob.type || "application/octet-stream",
    },
  };

  await axios.put(sasUrl, blob, config);
}

export async function getUserBlobSasUrl(blobPath: string, userId: string) {
  const urlResponse = await fetch(
    `${apiUrl}/api/GetUserReadWriteUrl?blobPath=${blobPath}`,
    {
      method: "GET",
      headers: {
        "agent-uuid": userId,
      },
    }
  );
  return await urlResponse.text();
}

export async function getUserSubscriptionInfo(
  userId: string,
  dispatch: Function
) {
  const response = await axios.get(`${apiUrl}/api/GetUserSubscriptionInfo`, {
    method: "GET",
    headers: {
      "agent-uuid": userId,
    },
  });
  const data = response.data;

  dispatch(setUserSubscription(data.subscription));
  dispatch(setUserSubscriptionEndDate(data.subscriptionEndDate));
  dispatch(setUserSubscriptionCancelled(data.subscriptionCancelled));
}

export async function cancelUserSubscriptionAsync(userId: string) {
  await fetch(`${apiUrl}/api/CancelUserSubscription`, {
    method: "DELETE",
    headers: {
      "agent-uuid": userId,
    },
  });
}
