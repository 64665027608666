import { useEffect, useRef } from 'react';
import p5 from 'p5';

export default function AnimatedBackground() {
  const containerRef = useRef<HTMLDivElement>(null);
  let size = { width: 512, height: 512 };

  useEffect(() => {
    let myp5: p5;

    const sketch = (p: p5) => {
      const animationSpeed = 1500;
      const rects = [
        { x: 0.42, y: 0.01, w: 0.58, h: 0.12 }, // background 2
        { x: 0.0, y: 0.02, w: 1.0, h: 0.05 }, // background 1
        { x: 0.83, y: 0.02, w: 0.15, h: 0.75 }, // layer 8
        { x: 0.69, y: 0.09, w: 0.15, h: 0.39 }, // layer 7
        { x: 0.56, y: 0.15, w: 0.15, h: 0.39 }, // layer 6
        { x: 0.47, y: 0.03, w: 0.15, h: 0.28 }, // layer 5
        { x: 0.35, y: 0.1, w: 0.15, h: 0.35 }, // layer 4
        { x: 0.22, y: 0.0, w: 0.15, h: 0.4 }, // layer 3
        { x: 0.1, y: 0.05, w: 0.15, h: 0.2 }, // layer 2
        { x: 0.0, y: 0.0, w: 0.15, h: 0.2 }, // layer 1
      ];

      p.setup = () => {
        p.createCanvas(size.width, size.height);
        p.frameRate(30);
        p.noStroke();
        rects.sort(() => Math.random() - 0.5);
      };

      p.draw = () => {
        p.background(p.color('#010519'));
        let colorOffset = 0;

        for (const r of rects) {
          let y = 1.0 - r.y - r.h; // transform from WebGL coords
          drawRect(
            r.x * p.width,
            y * p.height,
            r.w * p.width,
            r.h * p.height,
            (colorOffset += 1500),
            animationSpeed,
          );
        }

        p.filter(p.BLUR, (81 * p.width) / 640);
      };

      const drawRect = (
        x: number,
        y: number,
        w: number,
        h: number,
        offset: number,
        speed: number,
      ) => {
        const color1 = p.color('#f708f7');
        const color2 = p.color('#22f0f0');
        const color3 = p.color('#1515f4');

        const u_time = (p.millis() + offset) / speed;

        const transition1 = 0.5 + 0.5 * Math.sin(u_time * 2.0);
        const transition2 = 0.5 + 0.5 * Math.cos(u_time * 2.0);
        const transition3 = 0.5 + 0.5 * Math.sin(u_time * 2.0 + 1.0);

        let resultColor = p.lerpColor(
          p.lerpColor(color1, color2, transition1),
          p.lerpColor(color2, color3, transition2),
          transition3,
        );

        const pulse = 0.5 + 0.5 * Math.sin(p.frameCount * 0.02);
        resultColor = p.lerpColor(resultColor, p.color('#0105194C'), pulse / 2); // background color with opacity

        p.fill(resultColor);
        p.rect(x, y, w, h);
      };
    };

    if (containerRef.current) {
      myp5 = new p5(sketch, containerRef.current);

      const handleResize = (entries: ResizeObserverEntry[]) => {
        const entry = entries[0];
        if (entry) {
          size = entry.contentRect;
          myp5.resizeCanvas(size.width, size.height);
        }
      };

      // Create a ResizeObserver to observe the container
      const resizeObserver = new ResizeObserver(handleResize);
      resizeObserver.observe(containerRef.current);

      return () => {
        myp5.remove();
        resizeObserver.disconnect();
      };
    }
  }, []);

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: -1000,
      }}
    >
      <div ref={containerRef} style={{ width: '100%', height: '100%' }} />
    </div>
  );
}
