import { Box, Button } from "@mui/material";
import { useAppDispatch } from "../store/hooks";
import { useEffect, useState } from "react";
import SettingsView from "./SettingsView";
import Opening from "./controls/Opening";
import CustomAlert from "./dialogs/CustomAlert";
import { setUserId, setUserLeftCredits, setUserMail } from "../store/userSlice";
import {
  getUserAccount,
  getUserLeftCreditsAsync,
} from "../managers/ClientManager";
import AnimatedBackground from "./controls/AnimatedBackground";
import { useMsal } from "@azure/msal-react";
import { getUserSubscriptionInfo } from "../managers/storage/AzureStorageManager";
import { decodeEmail, encodeEmail } from "../helpers/Helper";

const MainView = () => {
  const { instance } = useMsal();
  const dispatch = useAppDispatch();
  const [loaded, setLoaded] = useState(false);
  const [isWidthSufficient, setIsWidthSufficient] = useState(
    window.innerWidth > 520
  );

  useEffect(() => {
    const handleResize = () => {
      setIsWidthSufficient(window.innerWidth > 520);
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const asyncWrapper = async () => {
      const searchParams = new URLSearchParams(window.location.search);
      const userId = searchParams.get("userId");

      if (userId) {
        dispatch(setUserId(userId));
        dispatch(setUserMail(decodeEmail(userId)));

        await getUserSubscriptionInfo(userId, dispatch);
        dispatch(setUserLeftCredits(await getUserLeftCreditsAsync(userId)));
      } else {
        await instance.handleRedirectPromise();
        const userAccount = await getUserAccount();

        if (!userAccount) return;

        const userId = encodeEmail(userAccount.username);

        if (userId)
          window.location.href = `${window.location.href}?userId=${userId}`;
        else {
          alert("Something went wrong. Please, try again");
        }
      }
      setLoaded(true);
    };

    asyncWrapper();
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        position: "relative",
        width: "100%",
        height: "100%",
      }}
    >
      <AnimatedBackground />
      <Box
        sx={{
          display: "flex",
          position: "relative",
          justifyContent: "start",
          width: "100%",
          height: "100%",
          overflow: "hidden",
        }}
      >
        <Button
          onClick={() => {
            window.location.href = "https://fotogeniq.me/";
          }}
          style={{
            position: "absolute",
            top: !isWidthSufficient ? 0 : 16,
            left: !isWidthSufficient ? 0 : 16,
            zIndex: 100,
          }}
        >
          <img
            src="assets/images/FotogeniqLogo.png"
            width={!isWidthSufficient ? "100px" : "150px"}
            height={!isWidthSufficient ? "30px" : "45px"}
          />
        </Button>
        <SettingsView />
        <Opening loaded={loaded} />
      </Box>
      <CustomAlert positionTop="56px" positionRight="16px" />
    </Box>
  );
};

export default MainView;
