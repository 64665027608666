import { configureStore } from "@reduxjs/toolkit";
import userSlice from "./userSlice";
import dialogSlice from "./dialogSlice";

export const store = configureStore({
  reducer: {
    user: userSlice,
    dialog: dialogSlice,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
