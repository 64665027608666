import { Box, Typography, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { getCustomAlertText, hideCustomAlert } from "../../store/dialogSlice";

type CustomAlertProps = {
  positionTop: string;
  positionRight: string;
};

const CustomAlert = ({ positionTop, positionRight }: CustomAlertProps) => {
  const dispatch = useAppDispatch();
  const alertText = useAppSelector(getCustomAlertText);
  const [isOpened, setIsOpened] = useState(false);

  useEffect(() => {
    setIsOpened(alertText !== "");
  }, [alertText]);

  return (
    <Box
      sx={{
        width: "360px",
        display: isOpened ? "flex" : "none",
        borderRadius: "16px",
        justifyContent: "start",
        position: "absolute",
        background: "#3050F5",
        flexDirection: "column",
        boxShadow: "0px 8px 33px 0px rgba(48, 80, 245, 0.30)",
        transition: "all 0.5s ease",
        top: positionTop,
        right: positionRight,
        zIndex: 10000,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Typography
          sx={{
            color: "#FFF",
            marginLeft: "16px",
            marginTop: "12px",
            lineHeight: "16px",
          }}
        >
          Oops!
        </Typography>
        <IconButton
          onClick={() => dispatch(hideCustomAlert())}
          size="small"
          sx={{
            margin: "4px",
            color: "white",
          }}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      </Box>
      <Typography
        sx={{
          fontSize: "14px",
          fontWeight: 500,
          color: "#FFF",
          margin: "0px 16px 16px 16px",
        }}
      >
        {alertText}
      </Typography>
    </Box>
  );
};

export default CustomAlert;
